import React from "react";
import { graphql } from 'gatsby';
// Component imports
import PainLoggerLayout from "../../components/pain_logger/PainLoggerLayout";
import AppFAQFullItem from "../../components/app_pages/AppFAQFullItem";
import SEO from "../../components/common/seo";


// Image imports

// CSS imports
import "../../assets/sass/app_page.scss";

export default ({ data }) => {
  return (
    <PainLoggerLayout>
      <SEO title="Pain Logger Frequently Asked Questions" keywords={[`pain`, `painlogger`, `faq`]} />
      <div className="app-navbar-padding">
      {
        data.faq_items.edges.map(({ node }) => {
          return (
            <AppFAQFullItem
              title={node.frontmatter.title}
              date={node.frontmatter.date}
              content={node.html}
            />
          );
        })
      }
      </div>
    </PainLoggerLayout >
  );
};

export const query = graphql`
  {
    faq_items: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/md/pain_logger/faq_*.md" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
          }
          id
          html
        }
      }
    }
  }
`;
